/* WARNING: This file should _only_ conntain bulma overrides and
   inclues to other scss files. It should not have any styling it
   self
*/

@charset "utf-8";

// Brand red
$red: rgb(239, 60, 36);

$primary: $red;

@use "../../../node_modules/bulma/sass" with(
  $family-sans-serif: 'Geograph, sans-serif',
  // Geograph only has regular and medium weights, so set everything over medium (500)
  // to be medium weight
  $title-weight: 500,
  $weight-bold: 500,
  $weight-semibold: 500,
  $button-weight: 400,

  $link: rgb(72, 95, 199),
  $primary: rgb(246, 204, 12),

  $navbar-breakpoint: 820px,
  $button-text-decoration: none,

  $card-radius: 0.25em,

  $tabs-link-active-border-bottom-color: var(--bulma-primary),
  $tabs-link-active-color: var(--bulma-primary),
  $tabs-toggle-link-active-background-color: var(--bulma-primary),
  $tabs-toggle-link-active-border-color: var(--bulma-primary),
  $tabs-toggle-link-active-color: var(--bulma-primary-invert),

  $breadcrumb-item-color: var(--bulma-text),
  $breadcrumb-item-hover-color: var(--bulma-text-strong),
  $breadcrumb-item-separator-color: var(--bulma-text),

  $box-radius: var(--bulma-radius-small),
);
@use "../../../node_modules/bulma/sass/utilities/mixins" as bulma-mixins;

@font-face {
  font-family: "Geograph";
  src: url("../delivereasy-ui/fonts/geograph-web-regular.woff2"),
       url("../delivereasy-ui/fonts/geograph-web-regular.woff");
  font-weight: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("../delivereasy-ui/fonts/geograph-web-regular-italic.woff2"),
       url("../delivereasy-ui/fonts/geograph-web-regular-italic.woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Geograph";
  src: url("../delivereasy-ui/fonts/geograph-web-medium.woff2"),
       url("../delivereasy-ui/fonts/geograph-web-medium.woff");
  font-weight: 500;
}

$family-sans-serif: Geograph, sans-serif;

/* TODO: Talk to Fannin, maybe delete */
.is-scroll-y {
  overflow-y: scroll;
}

.fills-container-vertically {
  height: 100%;
}

@import "../../../node_modules/bulma-list/sass/bulma-list.scss";
@import "partner/mantine";

// Custom helpers
// Sets the length of a line of text in characters
[style*="--line-length"] {
  max-width: calc(var(--line-length) * 1ch);
}

// TODO: figure out how to not have this suck

.partner-portal {
  min-height: 100vh;
  color: #4A4A4A;
  background: #FFFFFF;

  // In order to suppress Geograph's alternate form for the X in "1X2"
  font-variant-ligatures: no-contextual;

  @include bulma-mixins.tablet {
    display: flex;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    background: #FAFAFA;
    border-right: 1px solid #DDDDDD;
    padding: 1.5rem;

    @include bulma-mixins.tablet {
      min-width: 260px;
      height: 100vh;
      overflow-y: auto;
      position: sticky;
      top: 0;
    }

    .menu {
      .menu-list a {
        background: #FAFAFA;
      }

      .menu-list a:hover {
        background: #F1F2F4;
      }
    }
    .logo {
      width: 200px;
    }

    .sidebar-content {
      flex: 1;
    }

    .sidebar-footer {
      border-top: 1px solid #DDDDDD;
      margin-top: 1.5rem;
    }
  }

  .page-content {
    flex: 1;

    .page-header {
      padding: 1.5rem 3rem;
      border-bottom: 1px solid #DDDDDD;
    }

    .list-header {
      box-shadow: 0px 2px 4px 0px #0000001A;
    }

    // TODO: this isn't responsive AT ALL... I suspect we need a totally
    // custom mobile layout to make this huge amount of tabular data work on a phone
    .table {
      tbody {
        tr.unread {
          td:first-child:before {
            content:"";
            width:8px;
            height:8px;
            border-radius:50%;
            background: var(--bulma-primary);
            position: absolute;
          }
        }

        td.collapse {
          width: 0.1%;
          white-space: nowrap;
        }
      }
    }

    // TODO: This works well with the tables as they are now, but when we decide
    //how to handle the TODO above we'll need to use a different strategy here.
    nav.pagination {
      width: 800px;
    }
  }

  .fade-in {
    animation-duration: 0.5s;
    animation-name: animate-fade;
    animation-fill-mode: backwards;
  }

  .animation-delay-long {
    animation-delay: 5s;
  }

  .out-of-stock-button {
    margin-right: 10px;
  }

  .radio-margin {
    margin-right: 15px;
  }

  [data-tooltip] {
    position: relative;

    &::before, &::after {
      position: absolute;
      font-size: small;

      opacity: 0;
      z-index: 7;
    }

    &:hover {
      &::before, &::after {
        opacity: 1;
        transition: opacity 0.25s ease-in 0s;
      }
    }

    &:hover::after {
      content: attr(data-tooltip);
      color: white;
      background-color: var(--bulma-grey-dark);
      padding: 12px;
      border-radius: 6px;
      white-space: pre;
    }

    &:hover::after {
      top: 2em;
      left: 50%;
      transform: translate(-50%, 12px);
    }

    &.has-tooltip-arrow {
      &:hover::before {
        content: "";
        top: 2em;
        left: 50%;
        transform: translate(-50%, 0);
        border: 6px solid;
        border-color: transparent transparent var(--bulma-grey-dark) transparent;
      }
    }

    &.has-tooltip-left {
      &:hover::after {
        top: 0;
        left: 0;
        transform: translateX(calc(-100% - 6px));
      }

      &.has-tooltip-arrow {
        &:hover::before {
          content: "";
          top: 0;
          left: 0;
          transform: translate(-50%, 12px);
          border: 6px solid;
          border-color: transparent transparent transparent var(--bulma-grey-dark);
        }
      }
    }
  }

  @keyframes animate-fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}
